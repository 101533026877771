import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { HeroLogo } from "@components/svg/HeroLogo"
import { useGSAP } from "@gsap/react"
import { defineBlock } from "eddev/blocks"
import gsap from "gsap"
import { useRef } from "react"

export const meta: BlockMeta = {
  title: "Home Hero",
}

export default defineBlock("home/hero", (props) => {
  const logoRef = useRef<HTMLDivElement>(null)

  useGSAP(() => {
    if (logoRef.current) {
      const tl = gsap.timeline()
      tl.fromTo(logoRef.current, { scale: 1.2, opacity: 0 }, { scale: 1, opacity: 1, ease: "power2.out", duration: 2, delay: 1 })
    }
  }, { scope: logoRef })

  return (
    <BlockWrapper toolbarState="live-index" label="hero">
      <BlockItem>
        <div className="flex justify-center items-center w-full h-[70vh] md:h-[55vw] md:max-h-[85vh]">
          {props.video?.mediaItemUrl && (
            <video src={props.video.mediaItemUrl} className="absolute w-full h-full left-0 top-0 object-cover block" loop muted playsInline autoPlay />
          )}

          <div ref={logoRef} className="w-heroLogo mx-auto relative mt-9 md:mt-6">
            <HeroLogo />
          </div>
        </div>
      </BlockItem>
    </BlockWrapper>
  )
})